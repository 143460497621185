#okta-sign-in.auth-container.main-container {
    background-color: rgba(247, 247, 247, 0.15);
    width: 338px;
    border: none;
}

#okta-sign-in.auth-container .okta-sign-in-header {
    background-color: rgba(0, 46, 109, 0.5);
    height: 211px;
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
}

#okta-sign-in.auth-container .okta-sign-in-header:before {
    position:absolute;
    z-index:-1;
    top:0;
    left:0;
    width:100%;
    height:51px;
    content:"";
    background-color: #002e6d;
}
#okta-sign-in .auth-org-logo {
    width: 90px;
    height: 79.6px;
    object-fit: contain;
    max-width: initial;
    max-height: initial;
}
#amt-title-container {
    position: relative;
    width: 100%;
    margin-top: -10px !important;
    z-index: 100;
    text-align: center;
}
#line {
    width: 282px;
    height: 1px;
    border: solid 1px #4d7194 !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
#amt-title {
    height: 74px;
    font-family: OpenSans, sans-serif !important;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
    font-size: 54px !important;
    font-weight: 600 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    /*line-height: normal !important;*/
    letter-spacing: 6px !important;
    color: #ffffff;
}
#amt-subtitle {
    height: 24px;
    font-family: OpenSans, sans-serif !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: center !important;
    color: #ffffff;
    margin-top: 10px !important;
}
#okta-sign-in .auth-header {
    border-bottom: initial;
}
#okta-sign-in.auth-container h2, #okta-sign-in.auth-container h3 {
    display: none;
}
#okta-sign-in .o-form .custom-checkbox label {
    color: #002e6d;
}
#okta-sign-in.auth-container .link:link, #okta-sign-in.auth-container .link:visited, #okta-sign-in.auth-container .link:hover, #okta-sign-in.auth-container .link:active {
    color: #002e6d;
}
#okta-sign-in.auth-container .button-primary {
    border-radius: 4px;
    background: initial;
    background-color: #002e6d;
}
#okta-sign-in.auth-container .button-primary:hover {
    background: initial;
    background-color: #002e6d;
}
#okta-sign-in.auth-container .okta-form-input-field {
    border-color: #002e6d;
}
#sign-in-widget {
    height: auto;
}
#accent-dev {
    order: initial;
    height: 2px;
    border-style: solid !important;
    border-width: 2px !important;
    border-image-source: linear-gradient(to left, #c51313, #002e6d) !important;
    border-image-slice: 1 !important;
}
#accent-stg {
    order: initial;
    height: 2px;
    border-style: solid !important;
    border-width: 2px !important;
    border-image-source: linear-gradient(to left, #edb707, #002e6d) !important;
    border-image-slice: 1 !important;
}
#environment-dev {
    background-color: #aa1717 !important;
    color: white;
    padding: 5px !important;
    font-family: Helvetica !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: center !important;

}
#environment-stg {
    background-color: #edb707 !important;
    color: white;
    padding: 5px !important;
    font-family: Helvetica !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: center !important;

}
