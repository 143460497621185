.dropdown-menu.show {
    margin-left: 60px;
}

input::-webkit-input-placeholder {
    color: #d2d2d2 !important;
}

input:-moz-placeholder { /* Firefox 18- */
    color: #d2d2d2 !important;
}

input::-moz-placeholder {  /* Firefox 19+ */
    color: #d2d2d2 !important;
}

input:-ms-input-placeholder {
    color: #d2d2d2 !important;
}

@media (min-width: 768px) {
    #best-env-indicator {
        margin-left: 61px;
    }
}

.removeCheckBox .react-grid-checkbox-container  {
    display: none;
}

.full-page-table > div:first-child > div:first-child > .react-grid-Container > .react-grid-Main > .react-grid-Grid {
    height: calc(100vh - 360px);
    min-height: initial !important;
}

.react-grid-Cell--frozen, .react-grid-Cell--frozen:focus {
    z-Index: 0;
}
